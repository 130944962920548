


.windowmodal {
    bottom: 3.5em;
    height: 77vh;
    width: 80vw;
    left: 10vw;
    border-radius: 0.375rem;
    border: 1px solid #9CA3AF; 
    background-color: #121821;
    color: #FFFFFF; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    -webkit-backdrop-filter: blur(2rem);
    backdrop-filter: blur(2rem);
    animation: slide-in 0.2s ease-in;
  }

  @keyframes slide-in {
    from {
      transform: translateY(1000px);
    }
    to {
      transform: translateY(0px);
    }
  }



.modalappsbox {
  height: 77vh;
  overflow: hidden;
  padding: 4px 4px 2px 4px;
}

.modalappsbox input{
  background-color: #121212;
  border-color: #da9ce2;
  border-style: solid;
  border-width: 0 0 2px 0;
  border-radius: 5px;
  color: #ffffff;
  height: 40px;
  margin-top: 20px;
  outline: none;
  padding-left: 20px;
  width: 95%;
  margin-left:14px;
  margin-bottom: 20px;
}

.modalappsbox span {
  padding-left: 1rem;
  margin-top: 100px;
}

.modalapps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}


.modalapps .modalapptext {
  margin-top: 2px;
  text-align: center;
}


.modalfooter {
  position: absolute;
  bottom: 0;
  height: 7vh;
  width: 100%;
  border-top: 1px solid #cccccc;
}


.innermodalfooter {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 4px;
}

.innermodalfooter .modalfooterprofile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.innermodalfooter .modalfooterprofile .modalprofile {
  margin-right: 2px;
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.innermodalfooter .modalfooterprofile span {
  font-size: 1rem;
}


.powericon {
  padding: 7px;
  border-radius: 6px;
  font-size: 35px;
  margin-right: 20px;
}

.powericon:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.windowmodalpinnedappbutton {
  margin: 10px;
  width: 55px;
  min-height: 70px;
  border-radius: 0.25rem;
  padding: 0.25rem;
  background-color: transparent;
  border: none;
  color: white;
}

.windowmodalpinnedappbutton:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.pinnedapp {
  position: relative;
}

.pinnedappimage {
  display: block;
  margin: auto;
}

.pinnedappname {
  text-align: center;
  font-size: 0.75rem;
  margin-top: 10px;
}



  
  @media (min-width: 1024px) {
    .windowmodal {
      top: 17vh;
      left: 30vw;
      height: 77vh;
      max-width: 600px;
    }
  }
  
  @media (min-width: 768px) {
    .windowmodal {
      width: 60vw;
    }
  }

  @media (max-width: 450px) {
    .windowmodal {
      bottom: 14vh;
    }
  }
  